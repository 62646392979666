import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Container, NavLink, Row } from 'react-bootstrap'

// Defines the footer of every page on the website.
// TODO: Extract styles and strings into central file.
const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
        site {
        siteMetadata {
            title
        }
        }
    }
    `)
    return (
        <div style={{background:"#373f47"}}>
            <Container fluid="xxl" style={{paddingTop:"16px", paddingBottom:"16px"}}>
                <Row>
                    <Col style={{textAlign:"left"}}>
                        <NavLink href="/legal/privacy" style={{color:"#f5efed"}}>Privacy Policy</NavLink>
                        <NavLink href="/legal/privacy" style={{color:"#f5efed"}}>GDPR</NavLink>
                        <NavLink href="/legal/terms-of-use" style={{color:"#f5efed"}}>Terms of Use</NavLink>
                    </Col>
                    <Col style={{textAlign:"center"}}>
                        <NavLink href="/" style={{color:"#f5efed"}}>Home</NavLink>
                        <NavLink href="https://app.tnnl.co.uk" style={{color:"#f5efed"}}>Sign Up</NavLink>
                        <NavLink href="https://blog.tnnl.co.uk" style={{color:"#f5efed"}}>Blog</NavLink>
                    </Col>
                    <Col style={{textAlign:"right"}}>
                        <NavLink href="https://twitter.com/tnnlapp" style={{color:"#f5efed"}}>Twitter</NavLink>
                        <NavLink href="https://www.linkedin.com/company/tnnl/" style={{color:"#f5efed"}}>LinkedIn</NavLink>
                        <NavLink href="https://medium.com/@TNNL" style={{color:"#f5efed"}}>Medium</NavLink>
                    </Col>
                </Row>
                <Row>
                    <p style={{textAlign:"center", paddingTop:"16px", color:"#f5efed"}}><a style={{color:"#f5efed"}} href="https://www.algolia.com/">Powered by Algolia</a> | © {new Date().getFullYear()} {data.site.siteMetadata.title}</p>
                </Row>
            </Container>
        </div>
  )
}

export default Footer